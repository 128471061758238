import "../../../../styles/Utils.css";

import api from "../../../../services/api";
import { corIcones } from "../../../../../src/services/variables";
import { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { NomePortal } from "../../../../../src/services/variables";
import Loading from "../../../public/others/LoadingFull";
import GeneratePDF from "../../../../components/public/others/ordinances/download/pdf/PDF";

import exportFromJSON from "export-from-json";
import CarouselBienios from "./Bienios";
import ButtonTypeHidden from "./../utils/ButtonTypeHidden";

export default function ShowDocuments(props) {
  const { name, select } = props;

  const [removeLoading, setRemoveLoading] = useState(false);
  const [filter, setFilter] = useState("");
  const [portaria, setPortaria] = useState([]);
  const [portariaBACKUP, setPortariaBACKUP] = useState([]);

  const formatString = (data, keys) => {
    var resultString = [];

    for (const i in data) {
      const newData = keys
        .map((key) => {
          let value = data[i][key];
          if (key.toLocaleUpperCase().includes("DATA")) {
            // se for data
            const newFormatDate = dateRender(value);
            value = newFormatDate;
          }

          if (key.toLocaleUpperCase().includes("CNPJ")) {
            // se for cnpj

            if (value != undefined || value != null) {
              value = value.replace(
                /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
                "$1.$2.$3/$4-$5"
              );
            } else {
              value = "";
            }
          }
          if (
            typeof value != "string" &&
            key.toLocaleUpperCase().includes("VALOR")
          ) {
            //se não for string

            value = moneyFormat(value);
          } else if (typeof value != "string") {
            if (value != undefined || value != null) {
              value = value.toString();
            } else {
              value = "";
            }
          }
          return { [key]: value };
        })
        .reduce((prev, next) => {
          return { ...prev, ...next };
        }, {});
      resultString.push(newData);
    }
    return resultString;
  };
  const newObjectNegateDowland = (data, negateKeys) => {
    const resultKeysNegate = negateFilterKeys(data, negateKeys);
    return objectDownload(data, resultKeysNegate);
  };
  const objectDownload = (data, keys) => {
    var result = [];
    for (const i in data) {
      const newData = keys
        .map((key) => {
          let value = data[i][key];
          return { [key]: value };
        })
        .reduce((prev, next) => {
          return { ...prev, ...next };
        }, {});
      result.push(newData);
    }
    return result;
  };
  //remover as keys desnecessárias
  const negateFilterKeys = (data, negateKeys) => {
    return Object.keys(data[0]).filter((key) => !negateKeys.includes(key));
  };

  const moneyFormat = (money) => {
    if (money == "") {
      return money;
    } else {
      var format = { minimumFractionDigits: 2, currency: "BRL" };
      return money.toLocaleString("pt-BR", format);
    }
  };

  useEffect(() => {
    api
      .post(`/atosAdministrativos/list`, { type: select })
      .then((res) => {
        if (res.data.err !== true) {
          const result = res.data.res;

          if (result.length != 0) {
            const portaria = formatString(result, Object.keys(result[0]));
            setPortaria(portaria);
            setPortariaBACKUP(portaria);
          } else {
            setPortaria(result);
            setPortariaBACKUP(result);
          }
          setRemoveLoading(true);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (
      portariaBACKUP != null &&
      portariaBACKUP != undefined &&
      portariaBACKUP.length != 0
    ) {
      // console.log('a')

      const resultFilter = filterObject(
        portariaBACKUP,
        Object.keys(portariaBACKUP[0]),
        filter
      );
      setPortaria(resultFilter);
    }
  }, [filter]);
  // apenas filtrar
  const filterObject = (data, keys, find) => {
    find = find.toLocaleUpperCase();
    var result = [];
    for (const i in data) {
      var findOut = 0;
      const newData = keys
        .map((key) => {
          let value = data[i][key];

          if (value.toLocaleUpperCase().includes(find) && findOut == 0) {
            //verifica se já findOut a palavra desejada no objeto inteiro
            findOut = 1;
          }
          return { [key]: value };
        })
        .reduce((prev, next) => {
          return { ...prev, ...next };
        }, {});
      if (findOut == 1) {
        //se findOut adiciona no arrays
        result.push(newData);
      }
    }
    return result;
  };
  const dateRender = (dateReq) => {
    const nameMonth = {
      0: "01",
      1: "02",
      2: "03",
      3: "04",
      4: "05",
      5: "06",
      6: "07",
      7: "08",
      8: "09",
      9: "10",
      10: "11",
      11: "12",
    };
    const date = new Date(dateReq);
    if (dateReq != "") {
      date.setDate(date.getDate() + 1);

      var day = date.getDate();
      if (date.getDate() < 10) {
        var day = "0" + date.getDate();
      }
      const formatDate =
        day + "/" + nameMonth[date.getMonth()] + "/" + date.getFullYear();
      return formatDate;
    } else {
      return "";
    }
  };

  const XML = () => {
    const newData = newObjectNegateDowland(portaria, [
      "ID",
      "TIPO",
      "CNPJ",
      "ARQUIVO",
    ]);

    const fileName = `${select} ${NomePortal}`;
    const exportType = exportFromJSON.types.xml;
    exportFromJSON({ data: newData, fileName, exportType });
  };
  const CSV = () => {
    const newData = newObjectNegateDowland(portaria, [
      "ID",
      "TIPO",
      "CNPJ",
      "ARQUIVO",
    ]);

    const fileName = `${select} ${NomePortal}`;
    const exportType = exportFromJSON.types.csv;
    exportFromJSON({ data: newData, fileName, exportType });
  };
  const XLS = () => {
    const newData = newObjectNegateDowland(portaria, [
      "ID",
      "TIPO",
      "CNPJ",
      "ARQUIVO",
    ]);

    const fileName = `${select} ${NomePortal}`;
    const exportType = exportFromJSON.types.xls;
    exportFromJSON({ data: newData, fileName, exportType });
  };
  const JSON = () => {
    const newData = newObjectNegateDowland(portaria, [
      "ID",
      "TIPO",
      "CNPJ",
      "ARQUIVO",
    ]);

    const fileName = `${select} ${NomePortal}`;
    const exportType = exportFromJSON.types.json;
    exportFromJSON({ data: newData, fileName, exportType });
  };

  let mostrar_lista = true;

  switch (select) {
    case "Plano anual de contratações":
      portaria.length !== 0 ? (mostrar_lista = true) : (mostrar_lista = false);
      break;
    default:
      mostrar_lista = true;
      break;
  }

  return (
    <>
      {mostrar_lista && (
        <>
          {!removeLoading && <Loading />}
          <section className="box">
            <div className="search">
              <div className="from-portarias">
                <input
                  type="text"
                  id="search"
                  className="form__input"
                  autoComplete="off"
                  placeholder=" "
                  onChange={(e) => setFilter(e.target.value)}
                ></input>
                <label htmlFor="search" className="form-label-portarias">
                  Buscar
                </label>
                <div className="img-search-portarias">
                  <div className="icone-buscar-portarias">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icone-buscar-portarias"
                      width="20"
                      height="20"
                      fill={corIcones}
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </div>
                  {/* <img src="/icons/search.svg" alt="icone-download" className="icone-buscar-portarias"></img> */}
                </div>
              </div>
            </div>

            <div className="from-portarias">
              <div className="box-download">
                <div className="icone-download">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    className="icon-download-CSV"
                    onClick={(e) => CSV()}
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.517 14.841a1.13 1.13 0 0 0 .401.823c.13.108.289.192.478.252.19.061.411.091.665.091.338 0 .624-.053.859-.158.236-.105.416-.252.539-.44.125-.189.187-.408.187-.656 0-.224-.045-.41-.134-.56a1.001 1.001 0 0 0-.375-.357 2.027 2.027 0 0 0-.566-.21l-.621-.144a.97.97 0 0 1-.404-.176.37.37 0 0 1-.144-.299c0-.156.062-.284.185-.384.125-.101.296-.152.512-.152.143 0 .266.023.37.068a.624.624 0 0 1 .246.181.56.56 0 0 1 .12.258h.75a1.092 1.092 0 0 0-.2-.566 1.21 1.21 0 0 0-.5-.41 1.813 1.813 0 0 0-.78-.152c-.293 0-.551.05-.776.15-.225.099-.4.24-.527.421-.127.182-.19.395-.19.639 0 .201.04.376.122.524.082.149.2.27.352.367.152.095.332.167.539.213l.618.144c.207.049.361.113.463.193a.387.387 0 0 1 .152.326.505.505 0 0 1-.085.29.559.559 0 0 1-.255.193c-.111.047-.249.07-.413.07-.117 0-.223-.013-.32-.04a.838.838 0 0 1-.248-.115.578.578 0 0 1-.255-.384h-.765ZM.806 13.693c0-.248.034-.46.102-.633a.868.868 0 0 1 .302-.399.814.814 0 0 1 .475-.137c.15 0 .283.032.398.097a.7.7 0 0 1 .272.26.85.85 0 0 1 .12.381h.765v-.072a1.33 1.33 0 0 0-.466-.964 1.441 1.441 0 0 0-.489-.272 1.838 1.838 0 0 0-.606-.097c-.356 0-.66.074-.911.223-.25.148-.44.359-.572.632-.13.274-.196.6-.196.979v.498c0 .379.064.704.193.976.131.271.322.48.572.626.25.145.554.217.914.217.293 0 .554-.055.785-.164.23-.11.414-.26.55-.454a1.27 1.27 0 0 0 .226-.674v-.076h-.764a.799.799 0 0 1-.118.363.7.7 0 0 1-.272.25.874.874 0 0 1-.401.087.845.845 0 0 1-.478-.132.833.833 0 0 1-.299-.392 1.699 1.699 0 0 1-.102-.627v-.495Zm8.239 2.238h-.953l-1.338-3.999h.917l.896 3.138h.038l.888-3.138h.879l-1.327 4Z"
                    />
                  </svg>
                </div>
                <div className="icone-download">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    className="icon-download-XLS"
                    onClick={(e) => XLS()}
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM6.472 15.29a1.176 1.176 0 0 1-.111-.449h.765a.578.578 0 0 0 .254.384c.07.049.154.087.25.114.095.028.202.041.319.041.164 0 .302-.023.413-.07a.559.559 0 0 0 .255-.193.507.507 0 0 0 .085-.29.387.387 0 0 0-.153-.326c-.101-.08-.255-.144-.462-.193l-.619-.143a1.72 1.72 0 0 1-.539-.214 1.001 1.001 0 0 1-.351-.367 1.068 1.068 0 0 1-.123-.524c0-.244.063-.457.19-.639.127-.181.303-.322.527-.422.225-.1.484-.149.777-.149.305 0 .564.05.78.152.216.102.383.239.5.41.12.17.186.359.2.566h-.75a.56.56 0 0 0-.12-.258.625.625 0 0 0-.247-.181.923.923 0 0 0-.369-.068c-.217 0-.388.05-.513.152a.472.472 0 0 0-.184.384c0 .121.048.22.143.3a.97.97 0 0 0 .405.175l.62.143c.217.05.406.12.566.211a1 1 0 0 1 .375.358c.09.148.135.335.135.56 0 .247-.063.466-.188.656a1.216 1.216 0 0 1-.539.439c-.234.105-.52.158-.858.158-.254 0-.476-.03-.665-.09a1.404 1.404 0 0 1-.478-.252 1.13 1.13 0 0 1-.29-.375Zm-2.945-3.358h-.893L1.81 13.37h-.036l-.832-1.438h-.93l1.227 1.983L0 15.931h.861l.853-1.415h.035l.85 1.415h.908L2.253 13.94l1.274-2.007Zm2.727 3.325H4.557v-3.325h-.79v4h2.487v-.675Z"
                    />
                  </svg>
                </div>
                <div className="icone-download">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    className="icon-download-PDF"
                    onClick={(e) => GeneratePDF(portaria)}
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14 4.5V14a2 2 0 0 1-2 2h-1v-1h1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM1.6 11.85H0v3.999h.791v-1.342h.803c.287 0 .531-.057.732-.173.203-.117.358-.275.463-.474a1.42 1.42 0 0 0 .161-.677c0-.25-.053-.476-.158-.677a1.176 1.176 0 0 0-.46-.477c-.2-.12-.443-.179-.732-.179Zm.545 1.333a.795.795 0 0 1-.085.38.574.574 0 0 1-.238.241.794.794 0 0 1-.375.082H.788V12.48h.66c.218 0 .389.06.512.181.123.122.185.296.185.522Zm1.217-1.333v3.999h1.46c.401 0 .734-.08.998-.237a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.589-.68c-.264-.156-.599-.234-1.005-.234H3.362Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.14 1.14 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082h-.563v-2.707Zm3.743 1.763v1.591h-.79V11.85h2.548v.653H7.896v1.117h1.606v.638H7.896Z"
                    />
                  </svg>
                </div>
                <div className="icone-download">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    className="icon-download-XML"
                    onClick={(e) => XML()}
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM3.527 11.85h-.893l-.823 1.439h-.036L.943 11.85H.012l1.227 1.983L0 15.85h.861l.853-1.415h.035l.85 1.415h.908l-1.254-1.992 1.274-2.007Zm.954 3.999v-2.66h.038l.952 2.159h.516l.946-2.16h.038v2.661h.715V11.85h-.8l-1.14 2.596h-.025L4.58 11.85h-.806v3.999h.706Zm4.71-.674h1.696v.674H8.4V11.85h.791v3.325Z"
                    />
                  </svg>
                </div>
                <div className="icone-download">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    className="icon-download-JSON"
                    onClick={(e) => JSON()}
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M14 4.5V11h-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5ZM4.151 15.29a1.176 1.176 0 0 1-.111-.449h.764a.578.578 0 0 0 .255.384c.07.049.154.087.25.114.095.028.201.041.319.041.164 0 .301-.023.413-.07a.559.559 0 0 0 .255-.193.507.507 0 0 0 .084-.29.387.387 0 0 0-.152-.326c-.101-.08-.256-.144-.463-.193l-.618-.143a1.72 1.72 0 0 1-.539-.214 1.001 1.001 0 0 1-.352-.367 1.068 1.068 0 0 1-.123-.524c0-.244.064-.457.19-.639.128-.181.304-.322.528-.422.225-.1.484-.149.777-.149.304 0 .564.05.779.152.217.102.384.239.5.41.12.17.186.359.2.566h-.75a.56.56 0 0 0-.12-.258.624.624 0 0 0-.246-.181.923.923 0 0 0-.37-.068c-.216 0-.387.05-.512.152a.472.472 0 0 0-.185.384c0 .121.048.22.144.3a.97.97 0 0 0 .404.175l.621.143c.217.05.406.12.566.211a1 1 0 0 1 .375.358c.09.148.135.335.135.56 0 .247-.063.466-.188.656a1.216 1.216 0 0 1-.539.439c-.234.105-.52.158-.858.158-.254 0-.476-.03-.665-.09a1.404 1.404 0 0 1-.478-.252 1.13 1.13 0 0 1-.29-.375Zm-3.104-.033a1.32 1.32 0 0 1-.082-.466h.764a.576.576 0 0 0 .074.27.499.499 0 0 0 .454.246c.19 0 .33-.055.422-.164.091-.11.137-.265.137-.466v-2.745h.791v2.725c0 .44-.119.774-.357 1.005-.237.23-.565.345-.985.345a1.59 1.59 0 0 1-.568-.094 1.145 1.145 0 0 1-.407-.266 1.14 1.14 0 0 1-.243-.39Zm9.091-1.585v.522c0 .256-.039.47-.117.641a.862.862 0 0 1-.322.387.877.877 0 0 1-.47.126.883.883 0 0 1-.47-.126.87.87 0 0 1-.32-.387 1.55 1.55 0 0 1-.117-.641v-.522c0-.258.039-.471.117-.641a.87.87 0 0 1 .32-.387.868.868 0 0 1 .47-.129c.177 0 .333.043.47.129a.862.862 0 0 1 .322.387c.078.17.117.383.117.641Zm.803.519v-.513c0-.377-.069-.701-.205-.973a1.46 1.46 0 0 0-.59-.63c-.253-.146-.559-.22-.916-.22-.356 0-.662.074-.92.22a1.441 1.441 0 0 0-.589.628c-.137.271-.205.596-.205.975v.513c0 .375.068.699.205.973.137.271.333.48.589.626.258.145.564.217.92.217.357 0 .663-.072.917-.217.256-.146.452-.355.589-.626.136-.274.205-.598.205-.973Zm1.29-.935v2.675h-.746v-3.999h.662l1.752 2.66h.032v-2.66h.75v4h-.656l-1.761-2.676h-.032Z"
                    />
                  </svg>
                </div>
              </div>
              <label htmlFor="Download" className="form-label-portarias">
                Download
              </label>
            </div>
          </section>

          <section className="table-show-portarias">
            <br />
            <Table responsive>
              <thead>
                <tr>
                  <th className="B-portarias-th">Data</th>
                  <th className="B-portarias-th">Titulo</th>
                  <th className="B-portarias-th">Ações</th>
                </tr>
              </thead>
              <tbody>
                {portaria != undefined ? (
                  <>
                    {portaria.map((data, index) => (
                      <tr key={index}>
                        <td className="data-td-portais portarias-td">
                          {data.DATA}
                        </td>
                        <td className="title-td-portais portarias-td">
                          {data.TIPO == "Plano anual de contratações" ? (
                            <b>{data.TITULO}</b>
                          ) : (
                            data.TITULO
                          )}
                        </td>
                        <td className="acoes-td-portais portarias-td">
                          <ButtonTypeHidden
                            link={`https://administracaopublica.com.br/downloads?n=${data.CNPJ.replace(
                              "/",
                              ""
                            )
                              .replace(".", "")
                              .replace(".", "")
                              .replace("-", "")}&a=${data.ARQUIVO}`}
                            targetBlank={true}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              className="icon-download-DOWNLOAD"
                              viewBox="0 0 16 16"
                            >
                              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                          </ButtonTypeHidden>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <></>
                )}
              </tbody>
            </Table>
            {portaria.length == 0 && (
              <>
                <h6 className="resultTxt ordinances-result">
                  Nenhum Resultado Encontrado
                </h6>
              </>
            )}
            {portaria.length == 1 && (
              <>
                <div className="resultTxt ordinances-result"></div>
              </>
            )}
            {name == "Mural" && <CarouselBienios />}
          </section>
        </>
      )}
    </>
  );
}
