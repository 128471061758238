import NavMenu from "../../../components/public/others/Navbar";
import NavMenuCM from "../../../components/public/others/NavbarCM";
import NewsSearch from "../../../components/public/others/search/card/news";
import DiarySearch from "../../../components/public/others/search/card/diary";
import LegisSearch from "../../../components/public/others/search/card/legislacao";
import LicitSearch from "../../../components/public/others/search/card/licitacoes";
import SacopSearch from "../../../components/public/others/search/card/sacop";
import styled from "../../../styles/module/seachDatabase.module.css";
import Search from "../../../components/public/others/search/Search";
import Footer from "../../../components/public/others/Footer";
import { useParams } from "react-router-dom";
import { TipoPortal } from "../../../services/variables";
import SearchOffice from "../../../components/public/others/search/card/offices";

export default function SearchDatabasePage() {
  const props = useParams();

  return (
    <div>
      {TipoPortal == 3 ? <NavMenu /> : <NavMenuCM />}
      <div className={styled.rotesPage}>
        <h6>Início / Pesquisar / {props.wordSearch} </h6>
      </div>
      <Search />
      <NewsSearch wordSearch={props.wordSearch} />
      <DiarySearch wordSearch={props.wordSearch} />
      <LegisSearch wordSearch={props.wordSearch} />
      <LicitSearch wordSearch={props.wordSearch} />
      <SacopSearch wordSearch={props.wordSearch} />
      <SearchOffice wordSearch={props.wordSearch} />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}
