import { useEffect, useState } from "react";
import { TipoPortal } from "../../../../services/variables";
import NavMenu from "../../../../components/public/others/Navbar";
import NavMenuCM from "../../../../components/public/others/NavbarCM";
import Footer from "../../../../components/public/others/Footer";
import styles from "../../../../styles/module/transparency/dados_abertos.module.css";

import buttonsSACOP from "../../../../components/jsons/routesTranparency/routesSacopAndLicitacoes";
import buttonsATOS from "../../../../components/jsons/routesTranparency/routesAtcsAdministrative";
import {
  CSVexports,
  JSONexports,
  XLSexports,
  useGetAtos,
  useGetSacops,
} from "../../../../components/public/transparency/dados-abertos";

export default function DadosAbertosPage() {
  const [sacop, setSacop] = useState(null);
  const [ato, setAto] = useState(null);
  const [downloadParams, setDownloadParams] = useState(null);
  const { data, isLoading } = useGetAtos(ato);
  const { data: sacopData, isLoading: sacopLoading } = useGetSacops(sacop);

  useEffect(() => {
    if (downloadParams && !isLoading && !sacopLoading) {
      const { tipo, metodo, declaracao } = downloadParams;

      if (tipo === "ato") {
        handleExport({ data: data?.res, select: ato, metodo });
      } else if (tipo === "sacop") {
        let exportData = sacopData;
        if (declaracao != null && data != null) {
          exportData = [...sacopData, ["DECLARAÇÕES EM SEGUIDA"], ...data.res];
        }
        handleExport({ data: exportData, select: sacop, metodo });
      }
      setDownloadParams(null);
    }
  }, [downloadParams, isLoading, sacopLoading, data, sacopData, ato, sacop]);

  function handleExport({ data, select, metodo }) {
    if (data !== undefined && select !== null) {
      switch (metodo) {
        case "json":
          JSONexports({ data, select });
          break;
        case "xls":
          XLSexports({ data, select });
          break;
        case "csv":
          CSVexports({ data, select });
          break;
        default:
          return false;
      }
      setAto(null);
      setSacop(null);
      return true;
    }
    return false;
  }

  function handleDownload({ tipo, metodo }) {
    setAto(tipo);
    setDownloadParams({ tipo: "ato", metodo });
  }

  function handleDownloadSacop({ tipo, metodo, declaracao }) {
    setSacop(tipo);
    setDownloadParams({ tipo: "sacop", metodo, declaracao });
  }

  return (
    <>
      {TipoPortal === 3 ? <NavMenu /> : <NavMenuCM />}
      <div className="section-title rotes_pages">
        {(isLoading || sacopLoading) && <h2>Carregando...</h2>}
        <div className="section-title-portarias">
          <h4>Dados Abertos</h4>
          <p>Lista das informações abaixo para exportar</p>
        </div>
        <div className={styles.container_butons}>
          {buttonsSACOP.map((i, index) => (
            <div key={index} className={styles.button_rotes}>
              <p>{i.TITLE}</p>
              <div className={styles.acoes}>
                <b
                  onClick={() =>
                    handleDownloadSacop({
                      tipo: i.SELECT,
                      metodo: "json",
                      declaracao: i["SELECT-TYPE-DECLARACAO"],
                    })
                  }
                >
                  JSON
                </b>
                <b
                  onClick={() =>
                    handleDownloadSacop({
                      tipo: i.SELECT,
                      metodo: "xls",
                      declaracao: i["SELECT-TYPE-DECLARACAO"],
                    })
                  }
                >
                  XLS
                </b>
                <b
                  onClick={() =>
                    handleDownloadSacop({
                      tipo: i.SELECT,
                      metodo: "csv",
                      declaracao: i["SELECT-TYPE-DECLARACAO"],
                    })
                  }
                >
                  CSV
                </b>
              </div>
            </div>
          ))}
          {buttonsATOS.map((i, index) => (
            <div key={index} className={styles.button_rotes}>
              <p>{i.TITLE}</p>
              <div className={styles.acoes}>
                <b
                  onClick={() =>
                    handleDownload({ tipo: i.SELECT, metodo: "json" })
                  }
                >
                  JSON
                </b>
                <b
                  onClick={() =>
                    handleDownload({ tipo: i.SELECT, metodo: "xls" })
                  }
                >
                  XLS
                </b>
                <b
                  onClick={() =>
                    handleDownload({ tipo: i.SELECT, metodo: "csv" })
                  }
                >
                  CSV
                </b>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}
